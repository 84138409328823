import React, { useState, useEffect } from 'react';
import './expense.css';
import Sidebar from '../../components/sidebar/Sidebar';
import Topmenu from '../../components/topmenu/Topmenu';
import Widget from '../../components/widget/Widget';
import Breadcrumbs from '../../components/breadcrumb/Breadcrumbs';
import ExpenseFilter from '../../components/ExpenseFilter/Filter';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dropdown from 'react-bootstrap/Dropdown';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Cookies from 'universal-cookie'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import Entrypage from '../../components/entrypage/Entrypage';

import Pagination from "../../components/Pagination/Pagination"
import moment from 'moment/moment'
import axios from "axios";
import { url } from '../../util';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
const Expense = () => {
    const navigate = useNavigate();
    //for popup 
    const cookies = new Cookies();
    const token = cookies.get('fi_token')
    const [show, setShow] = useState(false);
    const [showData, setShowData] = useState({})
    const [totalExpense, setTotalExpense] = useState(0)
    const [totalExpenseNum, setTotalExpenseNum] = useState(0)
    const [totalEmpGranted, setTotalEmpGranted] = useState(0)
    const [totalEmpGrantedNum, setTotalEmpGrantedNum] = useState(0)
    const [totalPending, setTotalPending] = useState(0)
    const [totalPendingNum, setTotalPendingNum] = useState(0)
    const [total, setTotal] = useState(0)
    const [totalOuts, setTotalOuts] = useState(0)
    const handleClose = () => setShow(false);
    const handleShow = (data) => {
        setShow(true);
        setShowData(data)
    }
    const [limit, setLimit] = useState(50)
    const [offset, setOffset] = useState(0)
    const [employeeFilter, setEmployeeFilter] = useState({
        employee_query: '',
    })
  
    const [date, setDate] = useState([])
    const [ExpenseData, setExpenseData] = useState([])
    useEffect(() => {

        if (token === undefined) {
            navigate('/login')
        }
        const headers = { "Authorization": "Bearer " + token }

        let from_date = moment()
        axios.get(url+"api/getExpenses?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&limit=" + limit + "&offset=" + offset  , { headers }).then((response) => {
            setExpenseData(response.data)
        })
        axios.get(url+"api/getExpenses?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD")  , { headers }).then((response) => {
            setTotal(response.data.length)
        })
        from_date = moment()
        axios.get(url+"api/getTotalExpenseADay?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD"), { headers }).then((responseOne) => {
            setTotalExpense(responseOne.data[0].total === null ? 0 : responseOne.data[0].total)
            from_date = moment().subtract(1, 'd')
            axios.get(url+"api/getTotalExpenseADay?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD"), { headers }).then((responseTwo) => {
                setTotalExpenseNum(responseOne.data[0].total - responseTwo.data[0].total)
                from_date = moment()
                axios.get(url+"api/getTotalEmployeesExpending?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD"), { headers }).then((responseThird) => {
                    setTotalEmpGranted(responseThird.data[0].total)
                    from_date = moment().subtract(1, 'd')
                    axios.get(url+"api/getTotalEmployeesExpending?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD"), { headers }).then((responseFourth) => {

                        setTotalEmpGrantedNum(responseThird.data[0].total - responseFourth.data[0].total)
                        from_date = moment()
                        axios.get(url+"api/getPendingExpenses?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD"), { headers }).then((responseFifth) => {
                            setTotalPending(responseFifth.data[0].total)
                            from_date = moment().subtract(1, 'd')
                            axios.get(url+"api/getPendingExpenses?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD"), { headers }).then((responseSixth) => {
                                setTotalPendingNum(responseFifth.data[0].total - responseSixth.data[0].total)
                                axios.get(url+"api/getTotalOutSessions?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD"), { headers }).then((responseSeventh) => {
                                    setTotalOuts(responseSeventh.data[0].count_id)

                                })
                            })
                        })
                    })
                })
            })
        })
        let from_date_out = moment()
        axios.get(url+"api/getTotalOutSessions?from_date=" + from_date_out.format("YYYY-MM-DD") + "&to_date=" + from_date_out.add(1, 'd').format("YYYY-MM-DD"), { headers }).then((responseFourth) => {
            setTotalOuts(responseFourth.data[0].count_id)

        })
    }, [])
    const changeByEmployee = (data) => {

        // if(data.charAt(0)!=='1')
        //  {

        setEmployeeFilter((prevState) => {
            return { ...prevState, employee_query: data }
        })
    }
    const changeDate = (data) => {
        setDate(data)
    }
    // useEffect(() => {


    //     let from_date = moment(date)
    //     const headers = { "Authorization": "Bearer " + token }
    //     axios.get("http://localhost:9000/api/getExpenses?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&limit=" + limit + "&offset=" + offset  + "&location_name=" + "Store", { headers }).then((response) => {
    //         setExpenseData(response.data)
    //     })
    //     axios.get("http://localhost:9000/api/getExpenses?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD")  + "&location_name=" + "Store", { headers }).then((response) => {
    //         setTotal(response.data.length)
    //     })

    // }, [date])
    useEffect(() => {

        const headers = { "Authorization": "Bearer " + token }
        let getString = url+"api/getExpenses"
        if (date.length>1){
            let from_date=moment(date[0].toDate())
            
            let to_date=moment(date[date.length-1].toDate()).add(1,'d')
            getString+="&from_date="+from_date.format("YYYY-MM-DD")+"&to_date="+to_date.format("YYYY-MM-DD")
          }
          else if (date.length>0){
            let from_date=moment(date[0].toDate())
            
            let to_date=moment(date[date.length-1].toDate()).add(1,'d')
            getString+="&from_date="+from_date.format("YYYY-MM-DD")+"&to_date="+to_date.format("YYYY-MM-DD")
          }

        
        if (employeeFilter.employee_query != '') {
            getString += "&employee_query=" + employeeFilter.employee_query
            getString += "&limit=" + 10 + "&offset=" + 0
        } else {
            getString += "&limit=" + limit + "&offset=" + offset
        }




        axios.get(getString, { headers }).then((response) => {
            setExpenseData(response.data)
        })
         getString = url+"api/getExpenses"
         if (date.length>1){
            let from_date=moment(date[0].toDate())
            
            let to_date=moment(date[date.length-1].toDate()).add(1,'d')
            getString+="&from_date="+from_date.format("YYYY-MM-DD")+"&to_date="+to_date.format("YYYY-MM-DD")
          }
          else if (date.length>0){
            let from_date=moment(date[0].toDate())
            
            let to_date=moment(date[date.length-1].toDate()).add(1,'d')
            getString+="&from_date="+from_date.format("YYYY-MM-DD")+"&to_date="+to_date.format("YYYY-MM-DD")
          }
        if (employeeFilter.employee_query != '') {
            getString += "&employee_query=" + employeeFilter.employee_query
        }



        axios.get(getString, { headers }).then((response) => {
            setTotal(response.data.length)
        })

    }, [employeeFilter,date,limit,offset]);
    const selectEntries = (data) => {
        setLimit(data)
    }
    const selectPage = (data) => {
        setOffset((data - 1) * limit)
    }
    // useEffect(() => {

    //     const headers = { "Authorization": "Bearer " + token }
    //     let from_date = moment(date)
    //     axios.get("http://localhost:9000/api/getExpenses?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&limit=" + limit + "&offset=" + offset  + "&location_name=" + "Store", { headers }).then((response) => {
    //         setExpenseData(response.data)
    //     })
    //     axios.get("http://localhost:9000/api/getExpenses?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD")  + "&location_name=" + "Store", { headers }).then((response) => {
    //         setTotal(response.data.length)
    //     })
    // }, [limit, offset])
    return (
        <>

            <div className="home-page">
                <div className="content-side">
                    <Breadcrumbs heading='Expense' Btn={'Expense'} Btn_link={'/expense/add_expense'} activebread='Expense' />
                    <div className="widget">
                        <Widget title='Total Expense Granted' value={`₹${totalExpense}`} num={totalExpenseNum} duration='Day' />
                        <Widget title='Total Emp. Granted' value={totalEmpGranted} num={totalEmpGrantedNum} duration='Day' />
                        <Widget title='Pending Approvals' value={totalPending} num={totalPendingNum} duration='Day' />
                        <Widget title='Out From Store' value={totalOuts} duration='Hour' />
                    </div>
                    <ExpenseFilter date='Expense Date' data={ExpenseData} changeDate={changeDate} changeByEmployee={changeByEmployee} />
                    <div className="attend-table">
                        <TableContainer component={Paper} className='mb-4 expensetable'>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='text-start'>Employee Name</TableCell>
                                        <TableCell align="right">EmpID</TableCell>
                                        <TableCell align="right">Expense </TableCell>
                                        <TableCell align="right">Approval</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ExpenseData&&ExpenseData.map((val, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    <div className="empname d-flex">
                                                        {/* <img src={val.imgsrc} alt='clients' /> */}
                                                        <p>{val.employee_name}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center">{val.empID}</TableCell>
                                                <TableCell align="center">{val.amount}</TableCell>
                                                <TableCell align="center" className={val.cName}>
                                                    <Link to=''>{val.status}</Link> </TableCell>
                                                <TableCell align="center">
                                                    <Dropdown className='table-action'>
                                                        <Dropdown.Toggle> <BiDotsHorizontalRounded />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {/* <Link className='dropdown-item' to={"/expense/add/"+val.employee_id}>
                                                                        Add Expense </Link> */}
                                                            <Link className='dropdown-item' onClick={() => handleShow(val)}>
                                                                Details </Link>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Pagination selectEntries={selectEntries} selectPage={selectPage} offset={offset} limit={limit} total={total} />

                    </div>
                </div>
            </div>

            {/* Expense detail popup */}
            <Modal size="md" className='breakpopop expensepopop'
                aria-labelledby="contained-modal-title-vcenter"
                centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <h4>Expense Details </h4>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="4" xs="4">
                                Name
                            </Form.Label>
                            <Col sm="8" xs="8">
                                <Form.Control plaintext readOnly defaultValue={showData.employee_name} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="4" xs="4">
                                Floor
                            </Form.Label>
                            <Col sm="8" xs="8">
                                <Form.Control plaintext readOnly defaultValue={showData.floor_name} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="4" xs="4">
                                Category
                            </Form.Label>
                            <Col sm="8" xs="8">
                                <Form.Control plaintext readOnly defaultValue={showData.category_name} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="4" xs="4">
                                Time
                            </Form.Label>
                            <Col sm="8" xs="8">
                                <Form.Control plaintext readOnly defaultValue={showData.date?.split(" ")[1].substring(0, 8)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="4" xs="4">
                                Date
                            </Form.Label>
                            <Col sm="8" xs="8">
                                <Form.Control plaintext readOnly defaultValue={showData.date?.split(" ")[0].split("-").reverse().join("-")} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="4" xs="4">
                                Price
                            </Form.Label>
                            <Col sm="8" xs="8">
                                <Form.Control plaintext readOnly defaultValue={`₹${showData.amount}`} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="4" xs="4">
                                Approval
                            </Form.Label>
                            <Col sm="8" xs="8">
                                <Form.Control plaintext readOnly defaultValue={showData.status} />
                            </Col>
                        </Form.Group>
                        <Button className="send" onClick={handleClose}>
                            Ok
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Expense