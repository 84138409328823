import React, { useState, useEffect } from 'react';
import './gradedetail.css';
import Sidebar from '../../components/sidebar/Sidebar';
import Topmenu from '../../components/topmenu/Topmenu';
import Breadcrumbs from '../../components/breadcrumb/Breadcrumbs';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { TimingApprovalData } from '../timing-approval/TimingApprovalData';
import Rangeslide from '../../components/Rangeslide/Rangeslide';
import moment from 'moment/moment'
import axios from "axios";
import Cookies from 'universal-cookie'
import { useNavigate, useParams } from 'react-router-dom';
import { url } from '../../util';
const Gradedetail = () => {
    const cookies = new Cookies();
    const token = cookies.get('fi_token')
    const navigate = useNavigate();
    const [employeeDetails, setEmployeeDetails] = useState({})
    const { id } = useParams()
    const [grade_1st, setGradeIst] = useState(0)
    const [grade_2nd, setGrade2nd] = useState(0)
    const [grade_3rd, setGrade3rd] = useState(0)
    const [grade_4th, setGrade4th] = useState(0)
    useEffect(() => {

        if (token === null) {
            navigate('/login')
        }
        const headers = { "Authorization": "Bearer " + token }
        axios.get(url+"api/getEmployeeDetails?id=" + id, { headers }).then((response) => {

            setEmployeeDetails({ ...response.data.employeesResult[0], ...response.data.headEmployeesResult[0] })
        })
    }, [])
    const selectGrade1st = (data) => {
        console.log(data)
        setGradeIst(data)
    }
    const selectGrade2nd = (dataOne) => {
        setGrade2nd(dataOne)
    }
    const selectGrade3rd = (data) => {
        setGrade3rd(data)
    }
    const selectGrade4th = (data) => {
        console.log(data)
        setGrade4th(data)
    }
    console.log(grade_1st, grade_2nd, grade_3rd, grade_4th)
    const saveGrade = (e) => {
        e.preventDefault()

        if (token === undefined) {
            navigate('/login')
        }
        const headers = { "Authorization": "Bearer " + token }
        axios.post(url+"api/addGradesBYFI", {
            "employee_id": id,
            "grade_1st": grade_1st,
            "grade_2nd": grade_2nd,
            "grade_3rd": grade_3rd,
            "grade_4th": grade_4th
        }, { headers }).then((response) => {
            if (response) {
                navigate("/gradeemp")
            }
        })
    }

    return (
        <>
            <div className="home-page">
                <div className="content-side">
                    <Breadcrumbs heading='Grade Employee Details' activebread='Grade Employee' activebread1='Grade Employee Details' />
                    <div className="time-approval">
                        <Form>
                            <div className='emp-detail'>
                                <h3>Employee Details</h3>
                                <Form.Group as={Row} className="mb-3">
                                    {/* {TimingApprovalData.map((val, index) => { */}
                                    <>
                                        <Form.Label column sm="1" xs="4">
                                            Name
                                        </Form.Label>
                                        <Col sm="3" xs="8">
                                            <Form.Control plaintext readOnly defaultValue={employeeDetails?.name} />
                                        </Col>
                                        <Form.Label column sm="1" xs="4">
                                            Supervisor
                                        </Form.Label>
                                        <Col sm="3" xs="8">
                                            <Form.Control plaintext readOnly defaultValue={employeeDetails?.head_employee_name} />
                                        </Col>
                                        <Form.Label column sm="1" xs="4">
                                            Designation
                                        </Form.Label>
                                        <Col sm="3" xs="8">
                                            <Form.Control plaintext readOnly defaultValue={employeeDetails?.role_name} />
                                        </Col>
                                        <Form.Label column sm="1" xs="4">
                                            Gender
                                        </Form.Label>
                                        <Col sm="3" xs="8">
                                            <Form.Control plaintext readOnly defaultValue={employeeDetails?.gender} />
                                        </Col>
                                        <Form.Label column sm="1" xs="4">
                                            Floor
                                        </Form.Label>
                                        <Col sm="3" xs="8">
                                            <Form.Control plaintext readOnly defaultValue={employeeDetails.floor_name} />
                                        </Col>

                                    </>
                                    {/* ) */}
                                    {/* })} */}
                                </Form.Group>
                            </div>



                            <div className="behivour-detail">
                                <h5>BEHAVIOUR WITH CUSTOMER</h5>
                                <p>Choose Your options</p>
                                <Rangeslide selectValue={selectGrade1st} />
                            </div>

                            <div className="behivour-detail">
                                <h5>BEHAVIOUR WITH STAFF/HEAD</h5>
                                <p>Choose Your options</p>
                                <Rangeslide selectValue={selectGrade2nd} />
                            </div>
                            <div className="behivour-detail">
                                <h5>COUNTER CLEARANCE</h5>
                                <p>Choose Your options</p>
                                <Rangeslide selectValue={selectGrade3rd} />
                            </div>

                            <div className="behivour-detail">
                                <h5>PRESENTATION</h5>
                                <p>Choose Your options</p>
                                <Rangeslide selectValue={selectGrade4th} />
                            </div>

                            <div className="buttons pt-5">
                                <button className='Cancel' onClick={() => { navigate("/gradeemp") }}>Cancel</button>
                                <button className='send' onClick={(e) => saveGrade(e)}>Save</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Gradedetail