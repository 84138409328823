import React, { useEffect, useState } from 'react';
import './filter.css';
import Form from 'react-bootstrap/Form';
import { BiSearch } from 'react-icons/bi';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FaRegCalendarAlt } from 'react-icons/fa';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MainTable from '../MainTable/MainTable'
const Filter = (props) => {
    const [View, setView] = useState(false)

    let tableData = props?.data?.map((element) => (
      {
        employee_name: element.employee_name,
        id: element.id,
        photo:element.photo,
        empID:element.empID,
       
      }
    ))
    let newArray=[]
  let count=0
  if(tableData?.length>0){
    tableData.forEach((element)=>{
    let index=tableData.findIndex((ele)=>{
      return ele.empID===element.empID
    })
    if(index===count){
      newArray.push(element)
    }
    count+=1
    })
     }
     tableData=newArray
    const tableHeadings = [
        { heading: 'Employee Name' },
        { heading: 'Emp ID' }
      ]
      const [state, setstate] = useState('')
      const handleChange = (e) => {
        setView(true)
        const results = tableData.filter(post => {
          if (e.target.value === " ") return tableData
          return post.employee_name.toLowerCase().includes(e.target.value.toLowerCase())||post.empID.includes(e.target.value)
        })
        setstate({
          query: e.target.value,
          list: results
        })
      }
    //datepicker
   
    const [startDate, setStartDate] = useState(new Date());
    useEffect(()=>{
props.changeDate(startDate)
    },[startDate])
    return (
        <>
            <div className="filters">
                <Form>
                    <Row className='mb'>
                        <Form.Group as={Col} controlId="formGridCity" onBlur={() => { setView(false) }}>
                            <Form.Label>Employee</Form.Label>
                            <input type="text" class="form-control" placeholder="Emp ID , Employee Name.." onChange={handleChange} />
                            {/* <BiSearch onClick={(e)=>props.changeByEmployee(state.query)} /> */}
                            <div  className={`search_table ${View === true ? 'visible' : ''}`}>
          <MainTable  Inp={false} Btn={false} btnView={true} headings={tableHeadings} data={state.list === undefined ? tableData : state.list} keys={['employee_name', 'empID']} func={props.changeModalState} func2={props.changeByEmployee}/>
        </div>
                        </Form.Group>

                        {/* <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>Designation</Form.Label>
                            <Form.Select required>
                                <option value="" disabled selected>All Designation</option>
                                <option value="1">Designation 1</option>
                                <option value="1">Designation 2</option>
                                <option value="1">Designation 3</option>
                            </Form.Select>
                        </Form.Group> */}



                        {!props.isDate&&<Form.Group as={Col} controlId="formGridZip">
                            <Form.Label>{props.date} </Form.Label>
                            <DatePicker class="form-control" selected={startDate} onChange={(date) => setStartDate(date)} />
                            <FaRegCalendarAlt />
                        </Form.Group>}






                    </Row>
                </Form>
            </div>
        </>
    )
}

export default Filter