import './DetailsDiv.css'

const DetailsDiv = (props) => {
  return (
    <div key={props.num} className={`${'details_div'} ${props.cls===true?'wd50':''} ${props.cls2===true?'wd100':''}`}>
      {props.title}
      <span>{props.value}</span>
    </div>
  )
}

export default DetailsDiv