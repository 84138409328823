import React from 'react'
import GradeMeter from '../GradeMeter/GradeMeter'
import classes from './GradeMeterContainer.module.css'

const GradeMeterContainer = (props) => {
  return (
    <div className={classes.container}>
      {props.systemMarks&&<div className={classes.meter_container}>
        <GradeMeter progress={props.systemMarks} title={'Out of 60'} />
        <h3>System Marks</h3>
      </div>}
      {/* <span>And</span> */}
      <div className={classes.meter_container}>
        <GradeMeter progress={props.marksByFI} title={'Out of 40'} />
        <h3>FI Marks</h3>
      </div>
    </div>
  )
}

export default GradeMeterContainer