import { NavLink, Link } from 'react-router-dom';
import './sidebar.css';
import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { SidebarData } from './SidebarData';
import { IconContext } from 'react-icons';
import logo from '../../images/logo.png';

const Sidebar = () => {

    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);

 
  return (
    
    <>
    {/* for desktop */}
    <nav className="navbar-menu forpc">
        <div className='navbar-logo'>
            <Link to='/' className='logo'>
                <img src={logo} alt="logo" />
            </Link>
        </div>

        <ul className='nav-menu-items' >
            {SidebarData.map((item, index) => {
                return (
                    <li key={index} className={item.cName}>
                        <NavLink to={item.path}>
                            {item.icon}
                            <span>{item.title}</span>
                        </NavLink>
                    </li>
                );
            })}
        </ul>
    </nav>


    {/* for mobile */}
    <IconContext.Provider value={{ color: '#fff' }}>
        <div className='navbar formob'>
            <NavLink to='#' className='menu-bars'>
                <FaIcons.FaBars onClick={showSidebar} />
            </NavLink>
            <Link to='/' className='logo'>
                <img src={logo} alt="logo" />
            </Link>
        </div>
        <nav className={sidebar ? 'nav-menu formob active' : 'nav-menu'}>
            <ul className='nav-menu-items' onClick={showSidebar}>
                <li className='navbar-toggle'>
                    <NavLink to='#' className='menu-bars'>
                        <AiIcons.AiOutlineClose />
                    </NavLink>
                </li>
                {SidebarData.map((item, index) => {
                    return (
                        <li key={index} className={item.cName}>
                            <NavLink to={item.path}>
                                {item.icon}
                                <span>{item.title}</span>
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
        </nav>
    </IconContext.Provider>

</>

  )
}

export default Sidebar