

import client from '../../images/client.png';

export const AttendanceData = [
{
    id: 1,
    empid: '1242521',
    imgsrc: client,
    name: 'Abhishek',
    time: '8:00 Am',
    status: 'Present',
    approval: 'Send Request',
    cName: 'Present'

},
{
    id: 2,
    empid: '1242522',
    imgsrc: client,
    name: 'Ankit',
    time: '8:50 Am',
    status: 'Absent',
    approval: 'Send Request',
    cName: 'Absent'
},
{
    id: 3,
    empid: '1242523',
    imgsrc: client,
    name: 'Suresh',
    time: '9:00 Am',
    status: 'Present',
    approval: 'Send Request',
    cName: 'Present'
},
{
    id: 4,
    empid: '1242524',
    imgsrc: client,
    name: 'Pawan',
    time: '8:30 Am',
    status: 'Pending',
    approval: 'Send Request',
    cName: 'Pending'
},
{
    id: 5,
    empid: '1242525',
    imgsrc: client,
    name: 'Baljeet',
    time: '10:00 Am',
    status: 'Present',
    approval: 'Send Request',
    cName: 'Present'
},
]