import classes from './Sidebar.module.css'
import Img from '../../assets/logo.png'
import { Link, NavLink, useLocation } from 'react-router-dom'
import Attendence from '../../assets/Widget_add.png'
import Wallet from '../../assets/Wallet_alt.png'
import Chart from '../../assets/Chart.png'
import Check from '../../assets/Check_ring.png'
import Clock from '../../assets/Clock2.png'
import Arrows from '../../assets/arrows.png'
import Tie from '../../assets/Tie.png'
import User from '../../assets/User.png'
import Report from '../../assets/Desk.png'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { useEffect, useState } from 'react'
import { RxCross2 } from "react-icons/rx";
import { url } from '../../util'
const Sidebar = (props) => {
  const location = useLocation();
 
  const cookies = new Cookies();
  const token = cookies.get('fi_token')
  const headers = { "Authorization": "Bearer " + token }
  const [permissions, setPermissions] = useState([])
  useEffect(() => {
    axios.get(url + "api/getPermissions", { headers }).then((response) => {
      if (response.status === 200) {
        console.log(response.data)
        let permissions = response.data.map((perm) => {
          return perm.name
        })
        setPermissions(permissions)
      }
    })
  }, [])
  return (
    <div className={classes.sidebar}>
      <div className={classes.sidebar_header_top}>
        <Link className={classes.navbar_logo} to='/'><img src={Img} alt="logo" /></Link>
        <button onClick={() => { props.onSideberBtn() }}><RxCross2 /></button>
      </div>


      <ul className={classes.sidebar_ul}>
        <li>
          <NavLink onClick={() => { props.onSideberBtn() }} to={'/'} className={`${classes.sidebar_a} ${location.pathname == '/' ? 'active' : ''}`} ><img src={Attendence} alt="" /> Attendance</NavLink>
        </li>
        <li>
          <NavLink onClick={() => { props.onSideberBtn() }} to={'/approval'} className={`${classes.sidebar_a} ${location.pathname == '/' ? 'approval' : ''}`} ><img src={Attendence} alt="" /> Approval</NavLink>
        </li>
        <li>
          <NavLink onClick={() => { props.onSideberBtn() }} to={'/expense'} className={`${classes.sidebar_a} ${location.pathname.includes('expense') ? 'active' : ''}`}><img src={Check} alt="" />Expense</NavLink>
        </li>


        <li>
          <NavLink onClick={() => { props.onSideberBtn() }} to={'/gradeemp'} className={`${classes.sidebar_a} ${location.pathname.includes('gradeemp') ? 'active' : ''}`}><img src={Tie} alt="" />Grade Employee</NavLink>
        </li>
        <li>
          <NavLink onClick={() => { props.onSideberBtn() }} to={'/timing'} className={`${classes.sidebar_a} ${location.pathname.includes('timing') ? 'active' : ''}`}><img src={Clock} alt="" />Timing</NavLink>
        </li>


      </ul>
    </div>
  )
}

export default Sidebar