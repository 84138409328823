import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Attendance from "./pages/attendance/Attendance";
import Approval from "./pages/approval/Approval";
import Timing from "./pages/timing/Timing";
import Expense from "./pages/expense/Expense";
import TimingApproval from "./pages/timing-approval/TimingApproval";
import Gradeemp from "./pages/gradeemp/Gradeemp";
import Gradedetail from "./pages/gradedetail/Gradedetail";
import Login from "./pages/login/Login";
import AttendanceCorrect from "./pages/attendance/AttendanceCorrect";
import AddExpense from "./pages/expense/AddExpense/AddExpense";

import "./index.css";
import Layout from "./UI/Layout/Layout";
import View_Grade from "./pages/gradedetail/View_Grade/View_Grade";
import EditGrade from "../src/pages/gradedetail/Edit_Grade/Edit_Grade"
// import error from '../../images/error.png';
function App() {
  const urlPath = useLocation()
  const logPath = urlPath.pathname.includes('login');


  return (
    // <div className="App">
    // <BrowserRouter>
    //   <Routes>
    //     <Route path="/" element={<Attendance />} />

    //     <Route path='/'>
    //       <Route index element={<Attendance />} />
    //       <Route path='/correction' element={<AttendanceCorrect />} />
    //     </Route>

    //     <Route path="/approval" element={<Approval />} />

    //     <Route path='timing'>
    //       <Route index element={<Timing />} />
    //       <Route path='/timing/approval/:id/:attendance_id' element={<TimingApproval />} />
    //     </Route>
    //     <Route path='expense'>
    //       <Route index element={<Expense />} />
    //       <Route  path="/expense/add_expense" element={<AddExpense />} />
    //     </Route>

    //     <Route path='gradeemp'>
    //       <Route index element={<Gradeemp />} />
    //       <Route path='/gradeemp/detail/:id' element={<Gradedetail />} />
    //     </Route>
    //     <Route path="login" element={<Login />} />
    //     {/* <Route path='*' element={<div><img src={error}  alt='error' /></div>} /> */}
    //   </Routes>
    // </BrowserRouter>
    // </div>
    <>
   
   {logPath === true ?
        <Routes>
          <Route exact path='/login' element={<Login />} />
        </Routes >
        :
    <Layout>
      {
        
          <Routes>
            <Route path="/" element={<Attendance />} />
            
            <Route path="/">
              <Route index element={<Attendance />} />
              

              <Route path="/correction" element={<AttendanceCorrect />} />
            </Route>

            <Route path="/approval" element={<Approval />} />

            <Route path="timing">
              <Route index element={<Timing />} />
              <Route
                path="/timing/approval/:id/:attendance_id/:date_time"
                element={<TimingApproval />}
              />
            </Route>
            <Route path="expense">
              <Route index element={<Expense />} />
              <Route path="/expense/add_expense" element={<AddExpense />} />
            </Route>

            <Route path="gradeemp">
              <Route index element={<Gradeemp />} />
              <Route path="/gradeemp/view_grade/:id/:date" element={<View_Grade />} />
              <Route path="/gradeemp/detail/:id" element={<Gradedetail />} />
            </Route>
            
            {/* <Route path='*' element={<div><img src={error}  alt='error' /></div>} /> */}
          </Routes>
      }
    </Layout>
}
    </>
  );
}

export default App;
