import React, { useEffect } from 'react';
import './gradeemp.css';
import Sidebar from '../../components/sidebar/Sidebar';
import Topmenu from '../../components/topmenu/Topmenu';
import Breadcrumbs from '../../components/breadcrumb/Breadcrumbs';
import Filter from '../../components/filter/Filter';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dropdown from 'react-bootstrap/Dropdown';
import { BiDotsHorizontalRounded, BiFilterAlt } from 'react-icons/bi';
import Cookies from 'universal-cookie'
import { BsShop } from 'react-icons/bs';
import Entrypage from '../../components/entrypage/Entrypage';
import { Link } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import moment, { isMoment } from 'moment/moment'
import axios from "axios";
import Pagination from '../../components/Pagination/Pagination';
import { url } from '../../util';
const Gradeemp = () => {
    const commissionArray = [10000, 20000, 30000, 0, 0, 0, 0, 0, 10000, 20000]
    
    const [date, setDate] = useState(new Date())
    const cookies = new Cookies();
    const token = cookies.get('fi_token')
    const [limit, setLimit] = useState(50)
    const [offset, setOffset] = useState(0)
    const [total,setTotal] = useState(0)
    const [employeeFilter, setEmployeeFilter] = useState({
        employee_query: '',
    })
    const [GradeData, setGradeData] = useState([])
    const navigate = useNavigate();
    let from_date = moment().startOf('isoweek')
    const selectEntries = (data) => {
        setLimit(data)
    }
    const selectPage = (data) => {
        setOffset((data - 1) * limit)
    }
    useEffect(() => {

        if (token === undefined) {
            navigate('/login')
        }

        const headers = { "Authorization": "Bearer " + token }
        let getString = url+"api/getGradesManual?from_date=" + moment().startOf('isoweek').format("YYYY-MM-DD") + "&to_date=" + moment().startOf('isoweek').format("YYYY-MM-DD") + "&limit=" + limit + "&offset=" + offset + "&role_name=" + "Salesman"
        // commissionArray.forEach((data)=>{
        //     getString+="&commission="+data
        // })
        axios.get(getString, { headers }).then((responseOne) => {
            
        
axios.get(url+"api/getAllEmployees?limit="+limit+"&offset="+offset+ "&role_name=" + "Salesman",{headers}).then((response)=>{
    response.data.forEach((data)=>{
        let index=responseOne.data.findIndex((val)=>{
            return val.empID===data.empID
           })
                if(index!==-1){
                    data.grade=responseOne.data[index].grade_1st + responseOne.data[index].grade_2nd + responseOne.data[index].grade_3rd + responseOne.data[index].grade_4th
                }
                else{
                    data.grade=0
                }

    })
    setGradeData(response.data)
})
})
        getString = url+"api/getGradesManual?from_date=" + moment().startOf('isoweek').format("YYYY-MM-DD") + "&to_date=" + moment().startOf('isoweek').add(7, 'd').format("YYYY-MM-DD")  + "&role_name=" + "Salesman"
        // commissionArray.forEach((data)=>{
        //     getString+="&commission="+data
        // })
        axios.get(getString, { headers }).then((response) => {
            setTotal(response.data.length)
        })
        
    }, [])
    useEffect(() => {

        let from_date = moment(date).startOf('isoweek')
        const headers = { "Authorization": "Bearer " + token }

        let getString = url+"api/getGradesManual?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(7, 'd').format("YYYY-MM-DD") + "&role_name=" + "Salesman"
        if (employeeFilter.employee_query != '') {
            getString += "&employee_query=" + employeeFilter.employee_query
           
        } 
        // commissionArray.forEach((data)=>{
        //     getString+="&commission="+data
        // })

        axios.get(getString, { headers }).then((responseone) => {

           
        
        axios.get(url+"api/getAllEmployees?limit="+limit+"&offset="+offset+ "&role_name=" + "Salesman",{headers}).then((response)=>{
            response.data.forEach((data)=>{
                let index=responseone.data.findIndex((val)=>{
                    return val.empID===data.empID
                   })
                        if(index!==-1){
                            data.grade=responseone.data[index].grade_1st + responseone.data[index].grade_2nd + responseone.data[index].grade_3rd + responseone.data[index].grade_4th
                        }
                        else{
                            data.grade=0
                        }
        
            })
            setGradeData(response.data)
            
        })
    })
    axios.get(url+"api/getAllEmployees?&role_name=" + "Salesman",{headers}).then((response)=>{
      setTotal(response.data.length)
        })
    }, [employeeFilter, date, limit, offset])
    const changeByEmployee = (data) => {

        // if(data.charAt(0)!=='1')
        //  {

        setEmployeeFilter((prevState) => {
            return { ...prevState, employee_query: data }
        })
    }
    const changeDate = (data) => {
        setDate(data)
    }

    return (
        <>
            <div className="home-page">
                <div className="content-side">
                    <Breadcrumbs heading='Grade Employee' activebread='Grade Employee' />
                   
                    {/* <div className="floor-store" style={{ justifyContent: 'space-between' }}>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <BsShop /> Floor
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Floor 1</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Floor 2 </Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Floor 3 </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" className='text-black'>
                                        <BiFilterAlt /> Filter
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Filter 1</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Filter 2 </Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Filter 3 </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div> */}
                    <Filter date='Grade Week' data={GradeData} changeDate={changeDate} changeByEmployee={changeByEmployee} />
                    <div className="attend-table">
                        <TableContainer component={Paper} className='mb-4 expensetable'>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='text-start'>Employee Name</TableCell>
                                        <TableCell align="right">EmpID</TableCell>
                                        <TableCell align="right"> Floor </TableCell>
                                        <TableCell align="right">Grade</TableCell>
                                        <TableCell align="right">Status</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {GradeData.map((val, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    <div className="empname d-flex">
                                                        <p>{val.employee_name}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center">{val.empID}</TableCell>
                                                <TableCell align="center">{val.floor_name}</TableCell>
                                                <TableCell align="center">{ val.grade }</TableCell>
                                                <TableCell align="center">{val.grade===0?'Pending':'Done'}</TableCell>
                                                <TableCell align="center">
                                                    <Dropdown className='table-action'>
                                                        <Dropdown.Toggle> <BiDotsHorizontalRounded />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>

                                                            {
                                                                val.grade===0 && moment(date).isBetween(moment().startOf('week'),moment().endOf('week'))? <Link className='dropdown-item' to={`/gradeemp/detail/${val.id}`}  >
                                                                  Grade Employee </Link>:null
                                                            }

                                                            <Link to={`/gradeemp/view_grade/${val.id}/${date}`} className='dropdown-item'> Details</Link>
                                                            
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* <Entrypage selectEntries={selectEntries} selectPage={selectPage} /> */}
                        <Pagination selectEntries={selectEntries} selectPage={selectPage} offset={offset} limit={limit} total={total} />

                    </div>
                </div>
            </div>
        </>
    )
}

export default Gradeemp