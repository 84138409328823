import React,{useEffect,useState} from 'react';
import './login.css';
import loginlogo from '../../images/login-logo.png';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { VscEye } from 'react-icons/vsc';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import {url} from "../../util"
import jwtDecode from 'jwt-decode'
import Cookies from 'universal-cookie'
import { toast } from 'react-toastify';
const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [is_password,setIsPassword]=useState(true)
    const cookies = new Cookies();
    async function handleSubmit(event){
     
        event.preventDefault()
      axios.post(url+'api/auth/login',{
        username:username,
        password:password
      }).then((response)=>{
        const decode = jwtDecode(response.data.token)

        console.log(decode);

        cookies.set('fi_token',response.data.token,{
            expires: new Date(decode.exp * 1000)
        })

       if(response.data.token){
        setTimeout(() => {
            navigate('/')
        }, 500);
       }else{
        toast('Login failed please check credentials')
       }
      })
      }
    return (
        <>
            <div className="container">
                <div className="login-page">
                    <img src={loginlogo} alt='loginlogo' />
                    <Form className='login-form' onSubmit={(e)=>handleSubmit(e)}>
                        <Form.Group className="mb-4" controlId="formBasicName">
                            <Form.Label>Username</Form.Label>
                            <Form.Control  name="Username" value={username} onInput={(e)=>setUsername(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-4 password" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type={is_password?'password':'text'} name="Password" value={password} onInput={(e)=>setPassword(e.target.value)}/>
                            <VscEye className='pw-icon' onClick={()=>setIsPassword(!is_password)} />
                        </Form.Group>
                        <Button className="mt-2" type="submit" >
                            Login
                        </Button>
                    </Form>

                </div>
            </div>
        </>
    )
}

export default Login