import React, { useState, useEffect } from 'react';
import './timingapproval.css';
import Sidebar from '../../components/sidebar/Sidebar';
import Topmenu from '../../components/topmenu/Topmenu';
import Breadcrumbs from '../../components/breadcrumb/Breadcrumbs';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { TimingApprovalData } from './TimingApprovalData';
import { FaRegCalendarAlt } from 'react-icons/fa';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { useNavigate, useParams } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment/moment'
import axios from "axios";
import Cookies from 'universal-cookie'
import { url } from '../../util';
const TimingApproval = () => {
    const [time, setTime] = useState(null);
    const [download, setDownload] = useState(null)
    const { id, attendance_id,date_time } = useParams()
    const navigate = useNavigate();
    const cookies = new Cookies();
    const token = cookies.get('fi_token')
    const [reason, setReason] = useState('')
    const [employeeDetails, setEmployeeDetails] = useState({})
    //datepicker
    const [startDate, setStartDate] = useState(date_time.split("T")[0]);
    useEffect(() => {

        if (token === null) {
            navigate('/login')
        }
        const headers = { "Authorization": "Bearer " + token }
        axios.get(url+"api/getEmployeeDetails?id=" + id, { headers }).then((response) => {

            setEmployeeDetails({ ...response.data.employeesResult[0], ...response.data.headEmployeesResult[0] })
            
        })

    }, [])
console.log(time)
    const SubmitApproval = (event) => {
        event.preventDefault()
        console.log(time)
        const headers = { "Authorization": "Bearer " + token, 'Content-Type': 'multipart/form-data' }
        axios.post(url+"api/makeAttendanceCorrectionRequests", {
            "employee_id": id,
            "attendance_id": attendance_id,
            "date_time": startDate+ " "+ time,
            "download": download,
            "type": "Out For Some Work",
            "reason": reason
        }, { headers }).then((response) => {

            if (response.status===200) {

                toast.success('Timing Sent For  Approval')
                setTimeout(() => {
                    navigate(-1)
                }, 1000);

            }
        })
    }
    return (
        <>
            <div className="home-page">
                <div className="content-side">
                    <Breadcrumbs heading='Timing Approval' activebread='Timing' activebread1='Timing Approval' />
                    <ToastContainer></ToastContainer>
                    <div className="time-approval">
                        <Form enctype='multipart/form-data'>
                            <div className='emp-detail'>
                                <h3>Employee Details</h3>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="3" xs="6">
                                        Name
                                    </Form.Label>
                                    <Col sm="3" xs="6">
                                        <Form.Control plaintext readOnly defaultValue={employeeDetails?.name} />
                                    </Col>
                                    <Form.Label column sm="3" xs="6">
                                        Supervisor
                                    </Form.Label>
                                    <Col sm="3" xs="6">
                                        <Form.Control plaintext readOnly defaultValue={employeeDetails?.head_employee_name} />
                                    </Col>
                                    <Form.Label column sm="3" xs="6">
                                        Designation
                                    </Form.Label>
                                    <Col sm="3" xs="6">
                                        <Form.Control plaintext readOnly defaultValue={employeeDetails?.role_name} />
                                    </Col>
                                    <Form.Label column sm="3" xs="6">
                                        Gender
                                    </Form.Label>
                                    <Col sm="3" xs="6">
                                        <Form.Control plaintext readOnly defaultValue={employeeDetails?.gender} />
                                    </Col>
                                    <Form.Label column sm="3" xs="6">
                                        Floor
                                    </Form.Label>
                                    <Col sm="3" xs="6">
                                        <Form.Control plaintext readOnly defaultValue={employeeDetails.floor_name} />
                                    </Col>
                                    <Form.Label column sm="3" xs="6">
                                        Store
                                    </Form.Label>
                                    <Col sm="3" xs="6">
                                        <Form.Control plaintext readOnly defaultValue={employeeDetails.store_name} />
                                    </Col>

                                    <Form.Label column sm="3" xs="6">
                                        Store Department
                                    </Form.Label>
                                    <Col sm="3" xs="6">
                                        <Form.Control plaintext readOnly defaultValue={employeeDetails.store_department_name} />
                                    </Col>


                                    {/* ) */}
                                    {/* })} */}
                                </Form.Group>
                            </div>

                            <div className="time-filtr">
                                <Form.Group as={Col} controlId="formGridState">
                                            <Form.Label>Correct Time</Form.Label>
                                            <input type="time" name="" id="" step={1}  onChange={ (e)=>setTime(e.target.value)}/>
                                        </Form.Group>
                                <Form.Group as={Col} >
                                    <Form.Label> Date </Form.Label>
                                    <input type="date" name="" id=""  value={startDate} disabled/>
                                </Form.Group>
                            </div>
                            <Form.Group as={Col} className='mb-3'>
                                <Form.Label> Reason & Remarks </Form.Label>
                                <Form.Control as="textarea" rows={3} value={reason} onChange={(e) => setReason(e.target.value)} />
                            </Form.Group>
                            <Form.Group as={Col} className='pt-4'>
                                <Form.Label> Attach Files </Form.Label><br />
                                <input type='file'  onChange={(e) =>setDownload(e.target.files)} className="image-upload" />
                            </Form.Group>
                            <div className="buttons pt-5 mt-5">
                                <button className='Cancel' onClick={() => { navigate("/timing") }} >Cancel</button>
                                <button className='send' onClick={(e) => SubmitApproval(e)}>Send For Approval</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TimingApproval