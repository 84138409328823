import React from 'react'
import { Link } from 'react-router-dom'
import "./breadcrumbs.css"
const Breadcrumbs = (props) => {
  return (
    <div className={'heading_container'}>
      <div>
        <h2>{props.heading}</h2>
        <div className={'breadcrumb'}>FI / Dashboard</div>
      </div>
      {props.Btn &&
      <Link style={{'text-decoration': 'none'}} to={props.isEdit!==undefined?props.Btn_link+"/"+props.id:props.Btn_link}><button className={'heading_btn'}>{props.isEdit!==undefined?'Edit':'Add'} {props.Btn}</button></Link>}
      {
        props.BtnFunc && 
        <button style={{marginBottom:'0px'}} className={'heading_btn'} onClick={()=>props.BtnFunc()}>{props.BtnFuncName}</button>
      }
    </div>
  )
}

export default Breadcrumbs