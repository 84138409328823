import { ImUserTie } from 'react-icons/im';
import { GiGraduateCap } from 'react-icons/gi';
import { RiDashboardFill } from 'react-icons/ri';
import { FaImages } from 'react-icons/fa';
import { BsBadgeCcFill } from 'react-icons/bs';

export const SidebarData = [
    // {
    //     title: 'Dashboard',
    //     path: '/',
    //     icon: <RiDashboardFill />,
    //     cName: 'nav-text'
    // },
    {
        title: 'Attendance',
        path: '/',
        icon: <RiDashboardFill />,
        cName: 'nav-text'
    },
    {
        title: 'Approval',
        path: '/approval',
        icon: <GiGraduateCap />,
        cName: 'nav-text'
    },
    {
        title: 'Timing',
        path: '/timing',
        icon: <BsBadgeCcFill />,
        cName: 'nav-text'
    },
    {
        title: 'Expense',
        path: '/expense',
        icon: <ImUserTie />,
        cName: 'nav-text'   
    },
    {
        title: 'Grade Employee',
        path: '/gradeemp',
        icon: <FaImages />,
        cName: 'nav-text'
    },

]