import React from 'react';
import './widget.css';

const Widget = (props) => {
    return (
        <>

            <div className="wid-card">
                <p>{props.title}</p>
                <h1>{props.value}</h1>
                {props.num!==undefined&&<div className={`${'header_colored'} ${props.num >0 ? "green" : 'red'}`}>
                    {/* + {props.num} From Last {props.duration} */}
                    {props.num < 0 ? '- ' : '+ '}{props.num < 0 ? props.num * -1 : props.num} From Last {props.duration}
                </div>}
            </div>
        </>
    )
}

export default Widget