import React, { useEffect } from 'react';
import './attendance.css';
import Sidebar from '../../components/sidebar/Sidebar';
import Topmenu from '../../components/topmenu/Topmenu';
import Widget from '../../components/widget/Widget';
import Breadcrumbs from '../../components/breadcrumb/Breadcrumbs';
import Filter from '../../components/filter/Filter'
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from "../../components/Pagination/Pagination"
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import moment from 'moment/moment'
import axios from "axios";
import { url } from '../../util';
import Cookies from 'universal-cookie'
import { ToastContainer, toast } from 'react-toastify';


const Attendance = () => {
    const [employeeFilter, setEmployeeFilter] = useState({
        employee_query: '',
    })

  
    const cookies = new Cookies();
    const token = cookies.get('fi_token')
    const [date, setDate] = useState(new Date())
    const [total, setTotal] = useState(0)
    const [AttendanceData, setAttendanceData] = useState([])
    const [totalPresent, setTotalPresent] = useState(0)
    const [totalPresentNum, setTotalPresentNum] = useState(0)
    const [totalAbsent, setTotalAbsent] = useState(0)
    const [totalAbsentNum, setTotalAbsentNum] = useState(0)
    const [totalLeave, setTotalLeave] = useState(0)
    const [totalLeaveNum, setTotalLeaveNum] = useState(0)
    const [totalOuts, setTotalOuts] = useState(0)
    const [limit, setLimit] = useState(50)
    const [offset, setOffset] = useState(0)
    const navigate = useNavigate();
    useEffect(() => {

        console.log(token)
        if (token === undefined) {
            navigate('/login')
        }
        let from_date = moment(date)
        const headers = { "Authorization": "Bearer " + token }
        axios.get(url+"api/getAttendance?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&limit=" + limit + "&offset=" + offset , { headers }).then((response) => {
            setAttendanceData(response.data)
        })
        from_date = moment(date)
        axios.get(url+"api/getTotal?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&status='Present'", { headers }).then((response) => {
            from_date = moment(date).subtract(1, 'd')
            setTotalPresent(response.data[0].count_id)
            axios.get(url+"api/getTotal?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&status='Present'", { headers }).then((responseTwo) => {
                setTotalPresentNum(response.data[0].count_id - responseTwo.data[0].count_id)
            })
        })


        from_date = moment(date)
        axios.get(url+"api/getTotal?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&status='Absent'", { headers }).then((response) => {
            setTotalAbsent(response.data[0].count_id)
            from_date = moment(date).subtract(1, 'd')
            axios.get(url+"api/getTotal?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&status='Absent'", { headers }).then((responseTwo) => {
                setTotalAbsentNum(response.data[0].count_id - responseTwo.data[0].count_id)
            })
        })
        from_date = moment(date)
        axios.get(url+"api/getTotal?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&status='On leave'", { headers }).then((response) => {
            setTotalLeave(response.data[0].count_id)

            from_date = moment(date).subtract(1, 'd')
            axios.get(url+"api/getTotal?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&status='On leave'", { headers }).then((responseTwo) => {
                setTotalLeaveNum(response.data[0].count_id - responseTwo.data[0].count_id)
            })
        })
        let from_date_out = moment()
        axios.get(url+"api/getTotalOutSessions?from_date=" + from_date_out.format("YYYY-MM-DD") + "&to_date=" + from_date_out.add(1, 'd').format("YYYY-MM-DD"), { headers }).then((responseFourth) => {
            setTotalOuts(responseFourth.data[0].count_id)

        })
        axios.get(url+"api/getAllEmployees?role_name="+'Salesman', { headers }).then((responseOne) => {
            
            setTotal(responseOne.data.length)
        })

    }, [])
    useEffect(() => {


        let from_date = moment(date)
        const headers = { "Authorization": "Bearer " + token }
        axios.get(url+"api/getAttendance?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&limit=" + limit + "&offset=" + offset , { headers }).then((response) => {
            setAttendanceData(response.data)
        })
        from_date = moment(date)
        axios.get(url+"api/getAttendance?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") , { headers }).then((response) => {
            setTotal(response.data.length)
        })
    }, [date])

    const sendForApproval = (data) => {
        let from_date = moment(date)

        const headers = { "Authorization": "Bearer " + token }
        axios.post(url+"api/makeAttendanceCorrectionRequests", {

            "employee_id": data.employee_id,
            "attendance_id": data.attendance_id,
            "date_time": from_date.format("YYYY-MM-DD"),
            "type": "By Mistake"

        }, { headers }).then((response) => {
            if (response) {
                // window.location.reload(false)
                toast.success('Attendance Approval Requested!')
                fetchData()
            }
        })
    }
    const changeDate = (data) => {
        setDate(data)
    }
    const selectEntries = (data) => {
        setLimit(data)
    }
    const selectPage = (data) => {
        setOffset((data - 1) * limit)
    }

    const fetchData = () => {
        const headers = { "Authorization": "Bearer " + token }
        let from_date = moment(date)
        axios.get(url+"api/getAttendance?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&limit=" + limit + "&offset=" + offset , { headers }).then((response) => {
            setAttendanceData(response.data)
        })
        from_date = moment(date)
        axios.get(url+"api/getAttendance?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") , { headers }).then((response) => {
            setTotal(response.data)
        })
    }

    useEffect(() => {

        fetchData()

    }, [limit, offset])
    useEffect(() => {

        const headers = { "Authorization": "Bearer " + token }
        let from_date = moment()

        let getString = url+"api/getAttendance?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") 
        if (employeeFilter.employee_query != '') {
            getString += "&employee_query=" + employeeFilter.employee_query
            getString += "&limit=" + 10 + "&offset=" + 0
        } else {
            getString += "&limit=" + limit + "&offset=" + offset
        }


        axios.get(getString, { headers }).then((response) => {
            setAttendanceData(response.data)
        })
        from_date = moment()
        getString = url+"api/getAttendance?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD")
        if (employeeFilter.employee_query != '') {
            getString += "&employee_query=" + employeeFilter.employee_query
        }
        axios.get(getString, { headers }).then((response) => {
            setTotal(response.data.length)
        })


    }, [employeeFilter]);
    const changeByEmployee = (data) => {

        setEmployeeFilter((prevState) => {
            return { ...prevState, employee_query: data }
        })
    }
    return (
        <>
        <ToastContainer/>
            <div className="home-page">
                <div className="content-side">
                    <Breadcrumbs heading='Attendance' activebread='Attendance' />
                    <div className="widget">
                        <Widget title='Total Present' value={totalPresent} num={totalPresentNum} duration='Day'  />
                        <Widget title='Absent Employees ' value={totalAbsent} num={totalAbsentNum} duration='Day'  />
                        <Widget title='On Leave' value={totalLeave} num={totalLeaveNum} duration='Day'  />
                        <Widget title='Out From Store' value={totalOuts} duration='Hour'  />
                    </div>
                    <Filter isDate={true} date='Attendance Date' data={AttendanceData} changeDate={changeDate} changeByEmployee={changeByEmployee} />
                    <div className="attend-table">
                        <TableContainer component={Paper} className='mb-4'>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='text-start'>Employee Name</TableCell>
                                        <TableCell align="right">EmpID</TableCell>
                                        <TableCell align="right">Time</TableCell>
                                        <TableCell align="right">Attendance</TableCell>
                                        <TableCell align="right">Send for Approval</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {AttendanceData.map((val, index) => {
                                        return (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <div className="empname d-flex">
                                                        {/* <img src={url + val.photo} alt='clients' /> */}
                                                        <p>{val.employee_name}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center">{val.empID}</TableCell>
                                                <TableCell align="center">{val.datetime.split(" ")[1].substring(0, 8)}</TableCell>
                                                <TableCell align="center"><Link className={val.cName}>{val.status}</Link></TableCell>
                                                <TableCell align="center">{val.status === 'Absent' && <Link onClick={(e) => {
                                                    sendForApproval(val)
                                                }} >Send For Approval</Link>}</TableCell>
                                            </TableRow>
                                            //makeAttendanceCorrectionRequests
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination selectEntries={selectEntries} selectPage={selectPage} offset={offset} limit={limit} total={total} />

                    </div>
                </div>
            </div>
        </>
    )
}

export default Attendance