import React,{useState} from 'react';
import './rangleslide.css';
import Slider from 'react-rangeslider'



const RangeSlide =(props)=> {
   const [state,setState]=useState(0)
  const handleChange = value => {
setState(
      value
    )
    props.selectValue(value)
  }
  const Labels = {
    0: "0",
    1:"1",
    2:"2",
    3:"3",
    4:"4",
    5:"5",
    6:"6",
    7:"7",
    8:"8",
    9:"9",
    10:"10"

  }

 
        return(
            <Slider
            className='rangeslide'
          min={0}
          max={10}
          orientation='horizontal'
          value={state}
          labels={Labels}
          handleLabel={state}
          onChange={handleChange}
        />
        )
    
}
export default RangeSlide